#lightControl {
    background-color: #032757;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  #lightControl h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  #lightControl p {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  #lightControl h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  #lightControl ul {
    list-style-type: none;
    padding: 0;
  }
  
  #lightControl ul li {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  #lightControl ul li:before {
    content: '•';
    margin-right: 10px;
  }
  