#monitoring {
    background-color: #5483B3;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  #monitoring h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  #monitoring p {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  #monitoring ul {
    list-style-type: none;
    padding: 0;
  }
  
  #monitoring ul li {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  #monitoring ul li:before {
    content: '•';
    margin-right: 10px;
  }
  