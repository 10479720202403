.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #032757;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .home-container h1 {
    font-size: 3rem;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .home-container h1.home-gradient {
    background: linear-gradient(to right, #032757, #42c1e8); /* Gradiente de azul escuro para azul claro */
    -webkit-background-clip: text; /* Para aplicar o gradiente ao texto */
    background-clip: text;
    -webkit-text-fill-color: transparent; /* Torna o texto transparente para permitir o gradiente ser visível */
  }
  
  .home-container p {
    font-size: 1.5rem;
    max-width: 800px;
    margin-top: 30px;
    line-height: 1.5;
    color: #ddd;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Adiciona uma sombra escura ao redor do texto */
  }
  
  .home-highlight {
    color: #032757;
    font-weight: bold;
  }
  