#login {
    background-color: #5483B3;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  #login h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .login-form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .button-container {
    display: flex;
    justify-content: center; /* Centraliza o botão */
  }
  
  .login-button {
    padding: 10px 20px;
    background-color: #032757;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #42c1e8; /* Cor opcional para alterar ao passar o mouse */
  }
  