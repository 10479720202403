#contact {
    background-color: #4278af;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  #contact h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .contact-form textarea {
    resize: vertical; /* Permite que o usuário redimensione verticalmente o campo de texto */
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #032757;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #42c1e8; /* Cor opcional para alterar ao passar o mouse */
  }
  