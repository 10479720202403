.App {
  text-align: center;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #021022;
}

.company-name {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  letter-spacing: 1px;
  transition: transform 0.3s, color 0.3s;
}

nav ul li a:hover {
  transform: scale(1.1);
  color: #42c1e8; /* Cor opcional para alterar ao passar o mouse */
}

.section {
  height: 100vh; /* Cada seção ocupa a altura total da tela */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#home {
  background-color: #4278af;
}

#monitoring {
  background-color: #4278af;
}

#lightControl {
  background-color: #4278af;
}
